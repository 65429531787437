<template>
  <v-card outlined class="pa-2" style="height: 100%">
    <v-card-text>
      <v-row align="center">
        <v-icon :color="color">{{ icon }}</v-icon>
        <v-col class="py-0 rs-prospect-temp__header font-weight-bold">
          {{ headerText }}
        </v-col>
      </v-row>

      <v-row>
        <v-col
          class="text-center font-weight-bold rs-prospect-temp__value"
          :class="`${color}--text`"
          :style="{ textShadow: `0px 0px 20px ${rgbaColor}` }"
          >{{ valueText }}</v-col
        >
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-row>
        <v-col>
          <v-icon :color="getTrendColor" class="mr-2">{{
            `mdi-trending-${trending}`
          }}</v-icon>
          <span :class="`${getTrendColor}--text`">{{ trendingChange }}</span>
        </v-col></v-row
      >
      <v-btn small text
        >{{ btnText }} <v-icon small>mdi-arrow-right</v-icon></v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "red",
    },
    icon: {
      type: String,
      default: "mdi-fire-circle",
    },
    btnText: {
      type: String,
      default: "View Hot Prospects",
    },
    headerText: {
      type: String,
      default: "Hot Prospects",
    },
    valueText: {
      type: String,
      default: "12",
    },
    trending: {
      type: String,
      default: "neutral",
    },
    trendingChange: {
      type: String,
      default: "0.5%",
    },
    rgbaColor: {
      type: String,
    },
  },
  computed: {
    getTrendColor() {
      let color = "grey";

      if (this.trending === "up") {
        color = "success";
      } else if (this.trending === "down") {
        color = "error";
      }
      return color;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.rs-prospect-temp__value {
  font-size: 3rem;
  margin: 1rem auto;
}
</style>
